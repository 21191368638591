import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { CardMedia } from '@material-ui/core';
import raghav from '../../media/raghav.jpeg';


export default function RaghavCard() {
    return (
        <div>
        <Card sx={{ maxHeight: 50, maxWidth: 20 }}>
          <CardMedia
            component="img"
            height="300px"
            image={raghav}
            alt="Mather"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Raghav Sanagavarapu
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="medium" href="https://www.linkedin.com/in/raghav-sanagavarapu-b914481b7/">LinkedIn Profile</Button>
          </CardActions>
        </Card>
        </div>
      );
}