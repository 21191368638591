import React from 'react';
import {
    MethodologyContainer,
    Title,
    Paragraph,
    MethodologyImage
} from './MethodologyElements';
import methodologyImg from '../../media/methodology.png';
import Button from '@material-ui/core/Button';

const Methodology = () => {
    return (
        <>
            <MethodologyContainer id="Methodology">
                <Title>How It Works</Title>
                <MethodologyImage>
                    <img
                        id="methodology_img"
                        src={methodologyImg}
                        style={{
                            width: '100%',
                            height: '90%',
                            objectFit: 'cover',
                            position: 'center',
                        }}
                    />
                </MethodologyImage>
                    <Paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Nunc eget justo orci. Nunc blandit diam id
                        hendrerit scelerisque. Morbi sed vulputate velit.
                        Nulla facilisi. Vestibulum ante ipsum primis in
                        faucibus orci luctus et ultrices posuere cubilia
                        curae; Integer a lobortis ante, sit amet auctor
                        massa. Nam orci nunc, suscipit eu tristique vitae,
                        sollicitudin sit amet libero. Vivamus at blandit
                        odio, in sagittis nisl. Praesent et efficitur
                        nulla. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit.
                        <br />
                        <br />
                        Praesent leo neque, interdum eget
                        convallis nec, scelerisque at nunc. Praesent
                        ligula mauris, rutrum in dapibus in, tincidunt et
                        magna. Phasellus consequat porttitor finibus.
                        Vestibulum ante ipsum primis in faucibus orci
                        luctus et ultrices posuere cubilia curae; Aliquam
                        malesuada commodo metus id bibendum. Integer vel
                        tortor libero. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos
                        himenaeos.
                        <br />
                        <br />
                        <a href="/howItWorks">
                            Learn more
                        </a>
                    </Paragraph>
            </MethodologyContainer>
        </>
    );
};

export default Methodology;
