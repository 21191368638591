import styled from "styled-components";

export const MainTitle = styled.div`
    margin-top:5%;
    margin-bottom:5%;
    font-family: Trebuchet MS;
    color:white;
`

export const LandingContainer = styled.div`
    background-color: white;
    height: 790px;

`

export const PorticoBanner = styled.div`
    width:100%;
    height: 100%;
    animation: fadein 1s;
    animation-fill-mode: forwards;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

`

export const link = styled.div`

`
