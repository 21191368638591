import React from "react";
import { ResearchContainer, Title} from "./ResearchElements";

const Research = () => {
    return (<>
        <ResearchContainer id="Research">
            <Title>
                Our Research
            </Title>
            
        </ResearchContainer>
    
    
    
    </>);

}

export default Research;