import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { CardMedia } from '@material-ui/core';
import chloe from "../../media/Chloe_image.png";


export default function ChloeCard() {
    return (
        <div>
        <Card sx={{ Height: 50, Width: 20 }}>
          <CardMedia
            component="img"
            height="300px"
            image={chloe}
            alt="Chloe"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Chloe Taysom
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="medium" href="https://www.linkedin.com/in/satbir-v-04408518a">LinkedIn Profile</Button>
          </CardActions>
        </Card>
        </div>
      );
}
