import React from "react";
import { LoginContainer, LoginForm, Title, LoginButton, InputBox, LoginFormContainer } from './LoginElements';

import Navbar from "../Navbar";

const Login = () => {
    return (
        <>
            <Navbar/>
            {/* the navbar commented since the other links do not work as they jst scroll to a 
            part of the landing page, not redirect - try fix */}
            <LoginContainer>
                <LoginForm>
                    <Title>
                        Login
                    </Title>
                    <LoginFormContainer>
                        <InputBox placeholder="Username"/>
                        <InputBox type="password" placeholder="Password"/>
                     
                        <LoginButton>
                            Login
                        </LoginButton>
                    </LoginFormContainer>
                </LoginForm>
            </LoginContainer>
        </>

    )
}

export default Login;