import React from "react";
import { Title, ContactContainer, InputName, InputBox, FormContainer, TextArea} from './ContactElements'

const Contact = () => {
    return (
        <>
            <ContactContainer id="Contact">
                <Title>
                    Contact Us
                </Title>
                <FormContainer>
                <form>
                    <InputName>
                        Name:
                    </InputName>
                        <InputBox></InputBox>
                    <InputName>
                        Email:
                    </InputName>
                    <InputBox type="email"></InputBox>
                    <InputName>
                        Message:
                    </InputName>
                        <TextArea></TextArea>
                </form>
                </FormContainer>
                
            </ContactContainer>
        </>
    )
}
export default Contact;