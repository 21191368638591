import React from 'react';
import Landing from '../components/Landing';
import Navbar from '../components/Navbar';
import { useState } from 'react';
import About from '../components/About';
import Research from '../components/Research';

import { Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import Signup from "../components/Signup";
import FundScr from "../components/FundScreening"
import ShareData from "../components/ShareData"

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Routes>
                {/* <Route path="/about" element={<About />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/funds" element={<FundScr />} />
                <Route path="/shareData" element={<ShareData />} />
                <Route path="/" element={<Landing />} />
            </Routes>
        </>
    );
};

export default Home;
