import React from "react";
import { Nav,NavLogoName, NavbarContainer, NavItem, NavMenu,NavLinks, MenuIcon, NavScroll } from './NavbarElements';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TfiMenu } from "react-icons/tfi";

const Navbar = ({toggle}) => {
    return (
        <>
            {/* <Router> */}

            <Nav>
                <NavbarContainer>
                    <NavLogoName to="/">
                        Endowment Science
                    </NavLogoName>
                    <MenuIcon onClick={toggle}>
                        <TfiMenu />
                    </MenuIcon>
                    <NavMenu>
                        <NavItem>
                            <NavScroll
                                to="About"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                            >
                                About
                            </NavScroll>
                        </NavItem>
                        <NavItem>
                            <NavScroll
                                to="Methodology"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                            >
                                Methodology
                            </NavScroll>
                        </NavItem>
                        <NavItem>
                            <NavScroll
                                to="Research"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                            >
                                Research
                            </NavScroll>
                        </NavItem>
                        <NavItem>
                            <NavScroll
                                to="Contact"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Contact
                            </NavScroll>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="/Funds"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Funds
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="/shareData"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Share Data
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="/login"
                                style={{ display: 'inline-flex' }}
                            >
                                Login
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="/signup"
                                style={{ display: 'inline-flex' }}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                            >
                                Signup
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>

            {/* <Routes>
                <Route path="/about" element={<About />} />
            </Routes> */}
            {/* </Router> */}
        </>
    );
}

export default Navbar;