import logo from './logo.svg';
import './App.css';
import Home from './pages';
import About from './components/About';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Redirect,
} from 'react-router-dom';

function App() {
    return (
        <Home />
    );
}

export default App;
