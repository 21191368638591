import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import ScrollLink from 'react-scroll';

export const Nav = styled.nav`
    background:white;
    height: -80px;
    justify-content: center;
    // position: sticky;
    // top: 0;
    z-index:100;
    
    @media screen and (max-width:960px){
        transition:0.8s all ease;
    }
`
//sticky means that as you scroll down the navbar will stay at the top

export const NavbarContainer = styled.div`
    display:flex;
    justify-content: space-between;
    height:80px;
    width: 100%;
`

export const NavLogoName = styled(LinkRouter)`
    height: 50%;
    color: #24135e;
    display: flex;
    align-items: center;
    text-decoration:none;
    padding: 1rem;
    cursor:pointer;
    font-size:2.7rem;
    border-bottom: 4px solid transparent;
`
// when the screen reaches this dimension, do this..
//NavMenu is an undordered list, hence ul

export const MenuIcon = styled.div`
    display:none;
    
    @media screen and (max-width: 950px){
        display: block;
        position: absolute;
        top:0;
        right: 0;
        transform: translate(-100%, 60%);
        cursor: pointer;
        font-size: 1.9rem;
        color: #24135e;
    }
`
export const NavMenu = styled.ul`
    display:inline-flex;
    flex-direction:row;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 950px){
        display: none;
    }

`
export const NavItem = styled.li`
    display:inline-flex;
    flex-direction:row
    height:80px;
    overflow:auto;
`

export const NavScroll = styled(LinkScroll)`
    height: 100%;
    color: #24135e;
    display: flex;
    align-items: center;
    text-decoration:none;
    padding: 1rem;
    cursor:pointer;
    font-size:1.3rem;
    border-bottom: 4px solid transparent;

    //activate a style upon a link click
    &:active{
        color: green; 
    }

    &:hover{
        border-bottom: 4px solid #24135e;
    }
`

export const NavLinks = styled(LinkRouter)`
    height: 100%;
    color: #24135e;
    display: flex;
    align-items: center;
    text-decoration:none;
    padding: 1rem;
    cursor:pointer;
    font-size:1.3rem;
    border-bottom: 4px solid transparent;
    
    //activate a style upon a link click
    &:active{
        color: green; 
    }

    &:hover{
        border-bottom: 4px solid #24135e;
    }
`