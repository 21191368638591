import React from 'react'
import Navbar from "../Navbar";
import { useState } from 'react';
import { Title } from '../FundScreening/FundScreeningElements';
import Cookies from 'js-cookie';


// a form for public funds
// - Organisation
// - Contact name
// - Email
// - Telephone
// - Job title
// - Location
// - Website
// - Total AUM
// - Number of strategies

// At the end of the form should be a submit button for saving the data locally in JSON format for now

export default function Index() {
    
    var inputElementStyle = {
        width: "100%",
        height: "30px",
        margin: "20px",
        display: "flex",
        flexDirection: "column",
        color: "#321984"
    }
    var inputBoxStyle = {
        width: "100%",
        padding: "10px",
        margin: "5px 0px",
    }
    const DEFAULT_FORM_DATA = {
        "fund_type": "PR",
        "organisation": "",
        "contact_name": "",
        "email": "",
        "telephone": "",
        "job_title": "",
        "location": "",
        "website": "",
        "total_AUM": "",
        "number_of_strategies": ""
    }
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    
    const handleChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    
    const handleSubmit = event => {
        event.preventDefault();
        // clear form
        setFormData(DEFAULT_FORM_DATA);
        console.log(formData);
        // send POST request to backend /shareDataApi/create_fund_data/ under current address
        fetch('/shareDataApi/create_fund_data/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
            body: JSON.stringify(formData),
        })
    };

  return (
    <div>
        <Navbar/>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <Title>
                Share Data
            </Title>

            <form style={{display: "flex", flexDirection: "column", width: "50%"}} onSubmit={handleSubmit}>
                <label style={inputElementStyle}>
                    Fund type:
                    <select style={inputBoxStyle} name="fund_type" onInput={handleChange} value={formData.fund_type}>
                        <option value="PU">Public</option>
                        <option value="PR">Private</option>
                    </select>
                </label>
                <label style={inputElementStyle}>
                    Organisation:
                    <input style={inputBoxStyle} type="text" name="organisation" onInput={handleChange} value={formData.organisation} required/>
                </label>
                <label style={inputElementStyle}>
                    Contact name:
                    <input style={inputBoxStyle} type="text" name="contact_name" onInput={handleChange} value={formData.contact_name} required/>
                </label>
                <label style={inputElementStyle}>
                    Email:
                    <input style={inputBoxStyle} type="email" name="email" onInput={handleChange} value={formData.email} required/>
                </label>
                <label style={inputElementStyle}>
                    Telephone:
                    <input style={inputBoxStyle} type="tel" name="telephone" onInput={handleChange} value={formData.telephone} />
                </label>
                <label style={inputElementStyle}>
                    Job title:
                    <input style={inputBoxStyle} type="text" name="job_title" onInput={handleChange} value={formData.job_title} />
                </label>
                <label style={inputElementStyle}>
                    Location:
                    <input style={inputBoxStyle} type="text" name="location" onInput={handleChange} value={formData.location} />
                </label>
                <label style={inputElementStyle}>
                    Website:
                    <input style={inputBoxStyle} type="text" name="website" onInput={handleChange} value={formData.website} />
                </label>
                <label style={inputElementStyle}>
                    Total AUM:
                    <input style={inputBoxStyle} type="text" name="total_AUM" onInput={handleChange} value={formData.total_AUM} />
                </label>
                <label style={inputElementStyle}>
                    Number of strategies:
                    <input style={inputBoxStyle} type="text" name="number_of_strategies" onInput={handleChange} value={formData.number_of_strategies} />
                </label>
                <input style={{width: "fit-content", padding: "10px",margin: "20px", marginTop: "30px"}} type="submit" value="Submit" />
            </form>

        </div>
    </div>
  )
}
