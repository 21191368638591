import React from 'react'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

// id,Manager,Fund,Size $m,Strategy,Performance 12M,Performance 3Yr,Performance 5Yr,Performance 10Yr,Overall ES Score

export default function FundList({fund}) {

    const gridRef = React.useRef();
    var [rowData, setRowData] = React.useState(fund);
    // on fund update, set rowData to the new fund
    React.useEffect(() => {
        setRowData(fund);
    }, [fund]);

    const applyDefault = React.useCallback(() => {
        const {api, columnApi} = gridRef.current;
        if (api && columnApi) {
            columnApi.applyColumnState({
                state: [
                    {colId: 'Overall ES Score', sort: 'desc'},
                ],
                defaultState: {sort: null},
            });
            const allColumnIds = [];
            columnApi.getColumns().forEach((column) => {
              allColumnIds.push(column.getId());
            });
            columnApi.autoSizeColumns(allColumnIds);
        }
    }, []);

    var [columnDefs] = React.useState([
        {headerName: "Manager", field: "Manager", sortable: true, filter: true},
        {headerName: "Fund", field: "Fund", sortable: true, filter: true},
        {headerName: "Overall ES Score", field: "Overall ES Score", sortable: true, filter: true},
        {headerName: "Size $m", field: "Size $m", sortable: true, filter: true},
        {headerName: "Strategy", field: "Strategy", sortable: true, filter: true},
        {headerName: "Performance 12M", field: "Performance 12M", sortable: true, filter: true},
        {headerName: "Performance 3Yr", field: "Performance 3Yr", sortable: true, filter: true},
        {headerName: "Performance 5Yr", field: "Performance 5Yr", sortable: true, filter: true},
        {headerName: "Performance 10Yr", field: "Performance 10Yr", sortable: true, filter: true},
    ]);
    var defaultColDef = {
      resizable: true,
    };
    
    const searchInput = React.useRef();
    function handleSearchManager() {
        // search manager
        const thisSearchTerm = searchInput.current.value;
        const filteredData = fund.filter((row) => {
            return row.Manager.toLowerCase().includes(thisSearchTerm.toLowerCase());
        });
        setRowData(filteredData);
    }
    function handleSearchFund() {
        // search fund
        const thisSearchTerm = searchInput.current.value;
        const filteredData = fund.filter((row) => {
            return row.Fund.toLowerCase().includes(thisSearchTerm.toLowerCase());
        });
        setRowData(filteredData);
    }
    function handleClear() {
        // clear
        setRowData(fund);
    }
    return (
    <div>
      <div style={
        { display: 'flex', flexDirection: 'row', alignItems: 'left', margin: '10px 0px' }
      }>
        {/* search bar for manager and fund name */}
        <input type="text" ref={searchInput}/>
        <button onClick={handleSearchManager} style={{marginLeft: 10}}>Search Manager</button>
        <button onClick={handleSearchFund} style={{marginLeft: 10}}>Search Fund</button>
        <button onClick={handleClear} style={{marginLeft: 10}}>Clear</button>
      </div>

      <div className="ag-theme-alpine" style={{ height: 520, width: 1000 }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          animateRows={true}
          rowSelection="multiple"
          paginationPageSize={10}
          onCellValueChanged={applyDefault}
          onFirstDataRendered={applyDefault}
          onGridReady={applyDefault}
        />
      </div>

      <button style={{marginTop: 10, marginBottom: 10}}>Add Fund</button>
    </div>
  )
}
