import React from 'react'
import Navbar from "../Navbar";
import { Title,ScreeningContainer,ScreeningButton,SelectFundType } from './FundScreeningElements';
import FundList from './fundList';
// mock data import
import publicFunds from './mock_data/public_mock.json';
import privateFunds from './mock_data/private_mock.json';


export default function Index() {
    // a function switch between public and private funds
    var [viewingFund, setviewingFund] = React.useState(publicFunds);
    const switchFund = (fundType) => {
        if (fundType === "public"){
            setviewingFund(publicFunds);
        }else if (fundType === "private"){
            setviewingFund(privateFunds);
        }
    }
  return (
    <div>
        <Navbar/>
        <ScreeningContainer>
            <Title>
                Fund Screening
            </Title>
            <SelectFundType>
                <ScreeningButton onClick={() => switchFund("public")}>
                    Public Funds</ScreeningButton>
                <ScreeningButton onClick={() => switchFund("private")}>
                    Private Funds</ScreeningButton>
            </SelectFundType>
            <FundList fund={viewingFund}/>
        </ScreeningContainer>
    </div>
  )
}
