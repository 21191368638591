import React from 'react';
import {
    LandingContainer,
    MainTitle,
    PorticoBanner,
} from './LandingElements';
import banner from '../../media/portico.jpeg';
import Research from '../Research';
import About from '../About';
import Navbar from '../Navbar';
import Contact from '../Contact'
import Methodology from '../Methodology';


const Landing = () => {
    return (
        <LandingContainer>
            <Navbar />
            <PorticoBanner>
                <img
                    id="banner"
                    src={banner}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'center',
                    }}
                />
            </PorticoBanner>
            <About />
            <Methodology />
            <Research />
            <Contact id="Contact"/>
        </LandingContainer>
    );
};

export default Landing;
