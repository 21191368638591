import styled from "styled-components";

export const AboutContainer = styled.div`
    height:100%;
`

export const Title = styled.div`
    color: #24135e;
    display: flex;
    text-decoration:none;
    padding: 1rem;
    font-size:2.7rem;
    position: relative;
    justify-content: center;
`

export const PersonContianer = styled.div`
    margin: 20px;
    width: 300px;
    height: 90%;
    background-color: white;
`

export const People = styled.div`
    height: 80%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: scroll;
`