import styled from 'styled-components';


export const ContactContainer = styled.div`
    height: 100%;
`

export const Title = styled.div`
    color: #24135e;
    display: flex;
    text-decoration:none;
    padding: 1rem;
    font-size:2.7rem;
    position: relative;
    justify-content: center;

`

export const InputName = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    color: #321984;
    margin-top:2%;

`

export const InputBox = styled.input`
    width: 100%;
    padding: 10px;
    margin: 0px 0px;

`

export const FormContainer = styled.div`
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 80%;

`

export const TextArea = styled.textarea`
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0px 0px;
    resize: none;

`