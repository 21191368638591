import styled from "styled-components";

export const MethodologyContainer = styled.div`
    background-color: white;
    height: 100%;
`

export const Title = styled.div`
    color: #24135e;
    display: flex;
    text-decoration:none;
    font-size:2.7rem;
    position: relative;
    justify-content: center;
    
`
export const MethodologyImage = styled.div`
    width: 70%;
    height: 90%;
    animation: fadein 1s;
    animation-fill-mode: forwards;
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    display: inline-block
`

export const Paragraph = styled.div`
    width: 28%;
    background-color: white;
    display: inline-block;
    vertical-align: top;
`

export const img = styled.div`
    width: 80%;
    height: 100%;
    animation: fadein 1s;
    animation-fill-mode: forwards;
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

`
