import styled from "styled-components";

export const ResearchContainer = styled.div`
    height:100%;
    
`

export const Title = styled.div`
    color: #24135e;
    display: flex;
    text-decoration:none;
    font-size:2.7rem;
    position: relative;
    justify-content: center;
`