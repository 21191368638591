import styled from "styled-components";

export const ScreeningContainer = styled.div`
    color:white;
    height:90vh;
    overflow: None;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
`

export const Title = styled.div`
    font-size: 2rem;
    justify-content: center;
    display:flex;
    margin-top: 2rem;
    color: #24135e;
`
export const ScreeningButton = styled.button`
    color:white;
    width: 180px;
    height: 40px;
    font-size: 1rem;
    background-color: #24135e;
    border-radius:9px;
    margin: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active{
        color:#24135e;
        background-color:white;
    }
    &:hover{
        cursor:pointer;
    }
    
`

export const SelectFundType = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`