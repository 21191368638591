import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { CardMedia } from '@material-ui/core';
import baicheng from '../../media/baicheng.jpeg';


export default function BaichengCard() {
    return (
        <div>
        <Card sx={{ maxHeight: 60, maxWidth: 30 }}>
          <CardMedia
            component="img"
            height="300px"
            image={baicheng}
            alt="Baicheng"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Baicheng Yin
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="medium" href="https://www.linkedin.com/in/baicheng-yin">LinkedIn Profile</Button>
          </CardActions>
        </Card>
        </div>
      );
}