import React from "react";
import { useRef, useEffect } from 'react';
import { AboutContainer, Title, PersonContianer, People } from "./AboutElements";
// import "./About.css"
import ChloeCard from "../Cards/chloe";
import MatherCard from "../Cards/mather";
import SatbirCard from "../Cards/satbir";
import BaichengCard from "../Cards/baicheng";
import RaghavCard from "../Cards/raghav";
import MatthewCard from "../Cards/matthew";


const About = () => {
    // window.addEventListener("scroll", handleScroll);
    // function useHorizontalScroll() {
    //     const elRef = useRef();
    //     useEffect(() => {
    //         const el = elRef.current;
    //         if (el) {
    //             const onWheel = (e) => {
    //                 if (e.deltaY === 0) return;
    //                 e.preventDefault();
    //                 el.scrollTo({
    //                     left: el.scrollLeft + e.deltaY,
    //                     behavior: 'smooth',
    //                 });
    //             };
    //             el.addEventListener('wheel', onWheel);
    //             return () => el.removeEventListener('wheel', onWheel);
    //         }
    //     }, []);
    //     return elRef;
    // }

    return (<>
        <AboutContainer id="About">
            <Title>
                About Us
            </Title>
            <People id="People">
                
                <PersonContianer>
                    <ChloeCard></ChloeCard>
                </PersonContianer>

                <PersonContianer>
                   <MatthewCard></MatthewCard>
                </PersonContianer>
                
                <PersonContianer>
                    <SatbirCard></SatbirCard>
                </PersonContianer>

                <PersonContianer>
                   <BaichengCard></BaichengCard>
                </PersonContianer>

                <PersonContianer>
                   <RaghavCard></RaghavCard>
                </PersonContianer>

                <PersonContianer>
                    <MatherCard></MatherCard>
                </PersonContianer>
                
                </People>
        </AboutContainer>
    
    
    
    </>);

}

export default About;